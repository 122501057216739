
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

@import 'bootstrap/dist/css/bootstrap.min.css';

.divLoader
{
    width: 100%;
    height: 100%;
    background: gray;
    z-index: 9;
    position: absolute;
    z-index: 0.2;
    opacity: 0.3;
}
.svgLoader
{
    top: 35%;
    left: 50%;
    position: fixed;
    z-index: 9999;
    background-color: antiquewhite;
    border-radius: 22px;
    background: white;
}

.divShow
{
    display: block;
}
.divHide
{
    display: none;
}
.custom-style-1
{
    color: #eb317e;
    font-weight: 600;
}
